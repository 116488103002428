<script setup lang="ts">
import IconEyeOff from '~theme/assets/icons/outline/eye-off.svg?component';
import IconEyeOn from '~theme/assets/icons/outline/eye-on.svg?component';
import InputText from '~theme/components/atoms/InputText.vue';

const props = defineProps<{
  modelValue: string;
  invalid: boolean;
}>();

const emit = defineEmits<{ (e: 'update:modelValue', value: string): void }>();

defineOptions({
  inheritAttrs: false,
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value: string) {
    emit('update:modelValue', value);
  },
});
const mustHidePassword = ref(true);
const togglePasswordVisibility = () =>
  (mustHidePassword.value = !mustHidePassword.value);
const inputType = computed(() =>
  mustHidePassword.value ? 'password' : 'text'
);
const buttonIcon = computed(() =>
  mustHidePassword.value ? IconEyeOff : IconEyeOn
);
const attrs = useAttrs();
const classesAndStyleOfRootElement = computed(() => ({
  style: (attrs.style as string | undefined | null) ?? '',
  class: (attrs.class as string | undefined | null) ?? '',
}));
const inputAttrs = computed(() => ({ ...attrs, style: '', class: '' }));
</script>

<template>
  <div v-bind="classesAndStyleOfRootElement" :class="$style.wrapper">
    <input-text
      v-model="value"
      v-bind="inputAttrs"
      :invalid="invalid"
      :type="inputType"
      :class="$style.wrapper__input"
    />
    <button
      :class="[$style.wrapper__button, $style.button]"
      type="button"
      aria-label=""
      @click="togglePasswordVisibility"
    >
      <component :is="buttonIcon" :class="$style.button__icon" />
    </button>
  </div>
</template>

<style lang="scss" module>
.wrapper {
  position: relative;
  display: inline-block;

  &__input {
    padding-right: 3.4em;
    width: 100%;
  }

  &__button {
    position: absolute;
    top: 50%;
    right: 1.1em;
    width: auto;
    height: 1.6em;
    transform: translateY(-50%);
  }
}

.button {
  display: block;
  color: $color-text-and-object-medium-emphasis;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &__icon {
    width: auto;
    height: 1.6em;
    line-height: 1;
  }
}
</style>
